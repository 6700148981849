import dayjs, { Dayjs } from 'dayjs';
import { DropdownItem } from '../types/types';
import uniqid from 'uniqid';
import { dateFormatOnlyDateLong, dateFormatOnlyDateShort, mapFromAPIDateTime, mapFromAPIToDateShort } from 'utils/date';
import { ValidationError } from 'yup';
import { Location, OrderItemViewData } from '@components/pages/customer/new-expedition/types';
import {
  CargoDetailQuoteDemandOutDTO,
  CustomerQuoteStateEnum,
  GlobalTimeslotsDTO,
  LocationExpeditionPreviewOutDTO,
  OpeningModeEnum,
  TemplateCargoOutDTO,
  TimeslotCalendarDTO,
  TimeSlotResponse,
} from '../api/logsteo-api.v2';
import { useTranslation } from 'next-i18next';
import { calculateOrderWeight, calculateOrderWeightApi } from '@components/pages/customer/new-expedition/helpers';
import getConfig from 'next/config';

import clone from 'lodash/clone';
import toPath from 'lodash/toPath';
import React, { useState } from 'react';
import { CenteredRowWithGap } from '@components/common/styled';

export const generateTimeDropdownItems = (start: number, end: number, interval: number = 15): DropdownItem[] => {
  let temp = dayjs().set('hour', start).set('minute', 0).set('second', 0).set('millisecond', 0);
  const nextDay = temp.add(1, 'day');

  let response: DropdownItem[] = [];
  while (temp.get('hour') <= end && temp < nextDay) {
    response.push({
      label: temp.format('HH:mm'),
      value: temp.format('HH:mm'),
    });
    temp = temp.add(interval, 'minute');
  }
  return response;
};

export const isOfType = <T,>(varToBeChecked: any, propertyToCheckFor: keyof T): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined;

export const isLastItem = (itemIndex: number, collection: any[]) => itemIndex == collection.length - 1;

const log = (message: string, component: string = 'UNKNOWN', severity: string = 'INFO', user: string = 'anonymous') => {
  const now = dayjs().format('HH:MM:SS.SSS');
  console.log(`${now}: ${user}: ${component} - ${severity} - ${message}`);
};

export const getLog = (componentName: string, user: string): ((message: string, severity?: string) => void) => {
  return (message: string, severity: string = 'INFO') => {
    log(message, componentName, severity, user);
  };
};
export const uniq = () => {
  return uniqid();
};

export const isNullOrUndefined = (value: any) => value === undefined || value === null;
export const isNotNullOrUndefined = (value: any) => !isNullOrUndefined(value);
export const isNotBlank = (value: any) => value !== undefined && value !== null && `${value}`.length > 0;
export const isBlank = (value: any) => !isNotBlank(value);

export const getTime = (date: Dayjs): string => {
  if (isNullOrUndefined(date)) return null;
  return date.format('HH:mm');
};

export const numberRegex = /^\d+(\.\d{1,2})?$/;

export const formatPrice = (price: number, currency: string) => {
  return `${price?.toLocaleString('cs', { minimumFractionDigits: 2 }) || '-'} ${currency}`;
};

export const formatPercentage = (percentage: number) => {
  return `${percentage?.toLocaleString('cs', { minimumFractionDigits: 2 }) || '-'}`;
};

export const colorizeState = (status: string, state: string) => {
  switch (state) {
    case CustomerQuoteStateEnum.NEW: {
      return <span>{status}</span>;
    }
    case CustomerQuoteStateEnum.ACCEPTED_BY_CARRIER: {
      return <span className={'text-green'}>{status}</span>;
    }
    case CustomerQuoteStateEnum.REJECTED_BY_CARRIER: {
      return <span className={'text-red'}>{status}</span>;
    }
  }
};

export const formatTons = (amount: number) =>
  `${amount?.toLocaleString('cs', { minimumFractionDigits: 2 }) || ' - '} kg`;

export const downloadFile = (base64Data, fileName, mimeType) => {
  switch (mimeType) {
  }
  const linkSource = `data:${mimeType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const formatCarrierLocation = (zipCode: string, city: string, country: string): string => {
  return `${zipCode}, ${city} (${country})`;
};

export const formatLocationNotino = (zipCode: string, city: string, country: string, streetNr: string) => {
  return (
    <>
      <div>{streetNr}</div>
      <div>{`${zipCode} ${city}, ${country}`}</div>
    </>
  );
};

export const formatFromToAPITime = (since: string, till: string): string => {
  return `${getTime(mapFromAPIDateTime(since))} - ${getTime(mapFromAPIDateTime(till))}`;
};

export const mapFromDateToDayjs = (day: Date) => (isNullOrUndefined(day) ? null : dayjs(day));

export const formatDistance = (distance: number) => {
  return `${distance.toLocaleString()} km`;
};

export const formatWeight = (weight: number) => {
  if (isNullOrUndefined(weight)) return `- kg`;
  return `${weight.toLocaleString('cs', { minimumFractionDigits: 2 })} kg`;
};
export const formatPieces = (pieces: number, tr: any) => {
  if (isNullOrUndefined(pieces)) return `- ${tr('QtyUnit.KS')}`;
  return `${pieces.toLocaleString('cs', { minimumFractionDigits: 2 })} ${tr('QtyUnit.KS')}`;
};

export const formatHandlingUnits = (pieces: number, tr: any) => {
  if (isNullOrUndefined(pieces)) return `- ${tr('QtyUnit.KS')}`;
  return `${pieces.toLocaleString('cs', { minimumFractionDigits: 0 })} ${tr('QtyUnit.KS')}`;
};

export const dumpVars = (object: any) => {
  const { publicRuntimeConfig } = getConfig();
  const debug = publicRuntimeConfig.DUMPVAR_ENABLED;
  return (
    <>
      {debug && <pre>{JSON.stringify(object, null, 2)}</pre>}
      {!debug && <></>}
    </>
  );
};

export const resolveError = (path: string, errors: ValidationError[], t: any) => {
  const error = errors.find((e) => e.path === path);
  return error ? t(`validation.${error.type}`) : undefined;
};

/**
 * This function renders firstDate on the first location and the last date on the last location. The problem is that
 * if customer doesn't specify time (tillInUTC) and is set isNotSpecifiedTime we have to use sinceInUTC
 * @param location
 */

/**
 * This function renders firstDate on the first location and the last date on the last location. The problem is that
 * if customer doesn't specify time (tillInUTC) and is set isNotSpecifiedTime we have to use sinceInUTC
 * @param location
 */
export const formatInterval = (isWholeDay: boolean, since: string, till: string, t): string => {
  return isWholeDay
    ? `${t('TimeListItem.isWholeDay')}`
    : `${getTime(mapFromAPIDateTime(since))} - ${getTime(mapFromAPIDateTime(till))}`;
};

export const enumerate = (en: any): string[] => {
  return Object.keys(en).map((key) => en[key]);
};

interface OrderItem {
  height: number;
  width: number;
  length: number;
  quantity: number;
  quantityUnit: number;
  weightMode: string;
  weight: number;
}

/*
export const weightAndDimensions = (orderItem: OrderItem, t: any) => {
  // rozměry: ${orderItem.length} x ${orderItem.height} x ${orderItem.width} cm (d x š x v)
  const { height, length, width } = orderItem;
  return `${calculateOrderWeight(orderItem)} kg, ${t('common.dimensions', { height, length, width })}`;
};
*/

export const weightAndDimensions = (height: number, length: number, width: number, weight: number, t: any) => {
  // rozměry: ${orderItem.length} x ${orderItem.height} x ${orderItem.width} cm (d x š x v)
  return `${weight.toLocaleString('cs', { minimumFractionDigits: 2 })} kg, ${t('common.dimensions', {
    height,
    length,
    width,
  })}`;
};

export const first = <T,>(collection: T[]): T => {
  if (!isNullOrUndefined(collection) && collection.length > 0) return collection[0];
  return null;
};

export const parseNumberOrUndefined = (value: string): number => {
  const parsed = parseFloat(value);
  if (isNaN(parsed)) return undefined;
  return parsed;
};

export const dateToDayjs = (date: Date): Dayjs => dayjs(date);

export const convertToArray = (value: any) => {
  if (isNullOrUndefined(value)) return [];
  if (Array.isArray(value)) return value;
  return [value];
};

export const convertToDate = (value: any) => {
  if (typeof value === 'string') return dayjs(value).toDate();
  return value;
};

export const convertToNumberArray = (value: any) => {
  if (isNullOrUndefined(value)) return [];
  if (Array.isArray(value)) return value.map((t) => parseNumberOrUndefined(t));
  return [value];
};

export const convertToStrignArray = (value: any) => {
  if (isNullOrUndefined(value)) return [];
  if (Array.isArray(value)) return value.map((t) => t);
  return [value];
};

export const isObject = (object) => {
  return object != null && typeof object === 'object';
};

export const deepEqual = (object1, object2) => {
  if (object1 === object2) return true;
  if (isNullOrUndefined(object1)) return false;
  if (isNullOrUndefined(object2)) return false;

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};

export const addOrRemoveToSet = (object: any, add: boolean, list: any[]) => {
  let ret;
  if (add) {
    ret = [...list.filter((t) => !deepEqual(t, object)), object];
  } else {
    ret = list.filter((t) => !deepEqual(t, object));
  }
  return ret;
};

export type Identifier = string;

interface LocationFormat {
  streetNr: string;
  city: string;
  postalCode: string;
  country: string;
}

export const formatLocation = (location: LocationFormat) =>
  `${location.streetNr}, ${location.postalCode}, ${location.city}, ${location.country}`;

export const validateDate = (value, context) => {
  if (isNullOrUndefined(value)) return false;
  const d = dayjs(value);
  return d.isValid();
};

export const validateHours = (value, context) => {
  return validateHourString(value);
};

export const validateHourString = (value: string) => {
  if (isNullOrUndefined(value)) return false;
  const splitted = value.split(':');
  if (splitted.length == 1) {
    /*const number = parseInt(splitted[0]);
                        if (isNaN(number)) return false;
                        if (number >= 0 && number < 24) return true;*/
    return false;
  } else {
    if (splitted[1].length !== 2) return false;

    const number = parseInt(splitted[0]);
    if (isNaN(number)) return false;
    if (number > 24 || number < 0) return false;

    const number2 = parseInt(splitted[1]);
    if (isNaN(number2)) return false;
    if (number2 > 59 || number2 < 0) return false;

    if (number == 24 && number2 > 0) return false;

    return true;
  }
  return false;
};

// Hook
export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}

export const safeStringify = (obj, indent = 2) => {
  let cache = [];
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent,
  );
  cache = null;
  return retVal;
};

export const translateQuantityCode = (code: string, tr: any): string =>
  tr(`quantityCode${code}`, `quantityCode${code}`);

export const printYesNo = (value: boolean, tr: any) => {
  if (isNullOrUndefined(value)) return null;

  if (value === true) {
    return tr(`global.yes`, `Yes`);
  } else {
    return tr(`global.no`, `No`);
  }
};

export const printTimeslotResponse = (timeslot: TimeSlotResponse) => {
  if (isNullOrUndefined(timeslot)) return null;
  return `${dateFormatOnlyDateShort(dayjs(timeslot.localDate))} | ${timeslot.sinceHourString} - ${
    timeslot.tillHourString
  } (${timeslot.timeZone})`;
};

export const fixBooleanValue = (value): boolean => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return value === 'true';
  }
};

export function minMax<T>(elements: T[]): { min: T; max: T } {
  return elements.reduce(
    ({ min, max }, v) => ({
      min: min < v ? min : v,
      max: max > v ? max : v,
    }),
    { min: elements[0], max: elements[0] },
  );
}

export const europeanUnion: string[] = [
  'BEL',
  'BGR',
  'CZE',
  'DNK',
  'EST',
  'FIN',
  'FRA',
  'HRV',
  'IRL',
  'ITA',
  'CYP',
  'LTU',
  'LVA',
  'LUX',
  'HUN',
  'MLT',
  'DEU',
  'NLD',
  'POL',
  'PRT',
  'AUT',
  'ROU',
  'GRC',
  'SVK',
  'SVN',
  'ESP',
  'SWE',
];

export const invertColor = (hex: string) => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r, 2) + padZero(g, 2) + padZero(b, 2);
};

const padZero = (str: string, len: number): string => {
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};
